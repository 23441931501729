<template>
  <div class="MessageTemplates-app-list-page w-100">
    <Wrapper>
      
              <b-row>
                  <b-col cols="3">
                      <basic-input 
                       label= "Name"
                       placeholder="Nhập Name"
                       name="name"
                       :value.sync="filter.name">
                       </basic-input>
                  </b-col>
                  <b-col cols="3">
                      <basic-input 
                       label= "MessageType"
                       placeholder="Nhập MessageType"
                       name="messageType"
                       :value.sync="filter.messageType">
                       </basic-input>
                  </b-col>
                  <b-col cols="3">
                      <basic-input 
                       label= "MessageContain"
                       placeholder="Nhập MessageContain"
                       name="messageContain"
                       :value.sync="filter.messageContain">
                       </basic-input>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col cols="3">
                      <basic-input 
                       label= "Description"
                       placeholder="Nhập Description"
                       name="description"
                       :value.sync="filter.description">
                       </basic-input>
                  </b-col>
              </b-row>
      <b-row>
          <b-col cols="12">
            <b-dropdown
              id="dropdown-right dropdown-form"
              class="dropdown-form-filter"
              no-caret
              right>
              <template #button-content>
                <span class="svg-icon">
                  <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
                </span>
                Bộ lọc
              </template>
              <h6 class="d-flex align-items-center mb-0 p-4">
                <span class="svg-icon mr-3">
                  <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
                </span>
                Bộ lọc
              </h6>
              <b-dropdown-divider> </b-dropdown-divider>
              <b-dropdown-form>
                <b-container class="p-0">
                  
        <b-row>
            <b-col>
                <basic-input 
                 label= "Name"
                 placeholder="Nhập Name"
                 name="name"
                 :value.sync="filter.name">
                 </basic-input>
            </b-col>
            <b-col>
                <basic-input 
                 label= "MessageType"
                 placeholder="Nhập MessageType"
                 name="messageType"
                 :value.sync="filter.messageType">
                 </basic-input>
            </b-col>
            <b-col>
                <basic-input 
                 label= "MessageContain"
                 placeholder="Nhập MessageContain"
                 name="messageContain"
                 :value.sync="filter.messageContain">
                 </basic-input>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <basic-input 
                 label= "Description"
                 placeholder="Nhập Description"
                 name="description"
                 :value.sync="filter.description">
                 </basic-input>
            </b-col>
        </b-row>
                </b-container>
              </b-dropdown-form>
              <b-dropdown-divider> </b-dropdown-divider>
              <div class="d-flex align-items-center justify-content-lg-end m-0 p-4">
                <b-button
                  class="btn ml-2"
                  href="#"
                  tabindex="0"
                  @click="resetRequest"
                >
                  <span class="svg-icon">
                    <inline-svg
                      src="/media/svg/icons/Neolex/Basic/refresh-cw.svg"
                    />
                  </span>
                  Reset bộ lọc
                </b-button>
                <b-button
                  class="btn ml-2"
                  href="#"
                  tabindex="0"
                  @click="searchRequest"
                >
                  <span class="svg-icon">
                    <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
                  </span>
                  Lọc dữ liệu
                </b-button>
              </div>
            </b-dropdown>
            <b-button
              v-if="isWriteAllPermission"
              class="btn btn-success ml-2"
              type="button"
              @click="createItem"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
              </span>
              Thêm mới
            </b-button>
            <b-button
              class="btn btn-success ml-2"
              type="button"
              @click="importItems"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/upload-cloud.svg" />
              </span>
              Import
            </b-button>
          </b-col>
        </b-row>      
    </Wrapper>

    <b-overlay :show="loading">
      <b-container fluid class="MessageTemplates-list-page__body mb-6 mt-6">
        <b-row>
          <b-col>
            <div class="card card-custom gutter-b">
              <div class="card-body mt-0">
                <template-table
                  :column="column"
                  :data="data"
                  :paging="paging"
                  :tableAction="false"
                  :selectAction="false"
                  :searchAction="false"
                  @search="searchRequest"
                  @reset="resetRequest"
                  @sortBy="sortRequest"
                >
                  <template v-slot:body="{ item }">
                    <td style="width: 20px">
                      <action-dropdown
                        :value="item"
                        :show_edit="
                          isWriteAllPermission || isWriteOwnerPermission
                        "
                        :show_delete="false"
                        :show_copy="false"
                        :boundary="data.length > 2 ? 'scrollParent' : 'window'"
                        @view="viewItem"
                        @edit="editItem"
                        @copy="copyItem"
                      >
                        <b-dropdown-text
                          v-if="isWriteAllPermission || isWriteOwnerPermission"
                          tag="div"
                          class="navi-item cursor-pointer"
                        >
                          <a
                            class="navi-link cursor-pointer"
                            @click="deleteItem(item)"
                          >
                            <span
                              class="menu-icon svg-icon svg-icon-sm"
                              :class="
                                item.active ? 'text-danger' : 'text-primary'
                              "
                            >
                              <inline-svg
                                class="svg-icon"
                                src="/media/svg/icons/Neolex/Basic/power.svg"
                              />
                            </span>
                            <span
                              class="success navi-text"
                              :class="
                                item.active ? 'text-danger' : 'text-primary'
                              "
                            >
                              {{ item.active ? 'Inactive' : 'Active' }}
                            </span>
                          </a>
                        </b-dropdown-text>
                      </action-dropdown>
                    </td>
                    <td class="text-center">
                      {{ item.name }}
                    </td>
                    <td class="text-center">
                      {{ item.messageType }}
                    </td>
                    <td class="text-center">
                      {{ item.messageContain }}
                    </td>
                    <td class="text-center">
                      {{ item.description }}
                    </td>
                  </template>
                </template-table>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { TAG_TYPE } from '../../../core/plugins/constants';
export default {
  data() {
    return {
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      filter: {name: null,messageType: null,messageContain: null,description: null,
      },
      sort: {
        by: null,
        order: null,
      },      
      column: [
      {
          key: 'actionDropdown',
          label: '',
          sortable: false,
          style: {
            width: '5%',
          },
        },{
                key: 'name',
                label: 'Name',
                sortable: true,                
              },{
                key: 'messageType',
                label: 'MessageType',
                sortable: true,                
              },{
                key: 'messageContain',
                label: 'MessageContain',
                sortable: true,                
              },{
                key: 'description',
                label: 'Description',
                sortable: true,                
              },
              ],
      data: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
    isAdmin() {
      let roles = this.currentUser?.roles;
      if (roles && roles.length) {
        return roles.includes('Admin');
      } else {
        return false;
      }
    },
    isWriteAllPermission() {
      return this.$route.meta.isWriteAllPermission;
    },
    isWriteOwnerPermission() {
      return this.$route.meta.isWriteOwnerPermission;
    },
    searchParams() {
      const payload = this.removeEmpty({        name: this.filter.name,        messageType: this.filter.messageType,        messageContain: this.filter.messageContain,        description: this.filter.description,
        sortBy: this.sort.by ? `${this.sort.by} ${this.sort.order}` : null,
        page: this.paging.page,
        size: this.paging.pageSize,
      });
      const params = new URLSearchParams();
      for (const property in payload) {
        params.append(property, payload[property]);
      }      
      return params;
    },
  },
  watch: {
    'paging.page'() {
      this.loadData();
    },
    'paging.pageSize'() {
      this.loadData();
    },
  },
  methods: {
    searchRequest() {
      this.loadData();
      document.body.click();
    },
    resetRequest() {
        this.filter.name = null;      this.filter.messageType = null;      this.filter.messageContain = null;      this.filter.description = null;
      this.loadData();
      document.body.click();
    },
    sortRequest(sortData) {
      this.sort = {
        by: sortData.column,
        order: sortData.order,
      };
      this.loadData();
    },
    viewItem(item) {
      this.$router.push({
        name: 'MessageTemplates_detail',
        params: {
          id: item.id,
        },
      });
    },
    editItem(item) {
      this.$router.push({
        name: 'MessageTemplates_edit',
        params: {
          id: item.id,
        },
      });
    },
    createItem() {
      this.$router.push({
        name: 'MessageTemplates_create',
      });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        this.veeFields[ref].dirty &&
        this.veeFields[ref].validated
      ) {
        return this.veeFields[ref].valid;
      }
      return null;
    },
    importItems() {},
    deleteItem(item) {
      let text = item.active ? 'Inactive' : 'Active';
      let btn = item.active ? 'btn-inactive' : 'btn-active';
      this.$swal({
        title: '',
        text: `Bạn có chắc chắn muốn chuyển trạng thái ${item.id} thành ${text} không?`,
        icon: '/media/svg/icons/SweetAlert/alert-triangle-red.svg',
        buttons: {
          cancel: {
            text: 'Hủy',
            value: false,
            visible: true,
            className: 'btn btn-secondary',
            closeModal: true,
          },
          confirm: {
            text: 'Chắc chắn',
            value: true,
            visible: true,
            className: `btn ${btn}`,
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            // inactive all
            this.changeStatus(item);
          }
        }.bind(this),
      );
    },
    copyItem(item) {
      this.$router.push({
        name: 'MessageTemplates_detail',
        params: {
          form_type: 'copy',
          id: item.id,
        },
      });
    },
    async changeStatus(item) {
      this.$store.commit('context/setLoading', true);
      const params = {
        id: item.id,
        active: !item.active,
      };
      const { error } = await this.$store.dispatch('CHANGE_STATUS', params);

      if (error) {
        this.$store.commit('context/setLoading', false);
        return this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      }
      this.$toastr.s({
        title: 'Thành công !',
        msg: 'Cập nhật thành công',
      });
      this.$store.commit('context/setLoading', false);
      this.loadData();
    },
    async loadData() {
      try {
        this.$store.commit('context/setLoading', true);
        const { data } = await this.$api.get('Admin/MessageTemplates/portal', {
          params: this.searchParams,
        });
        this.data =
          data.items.map((el) => {
            return {
              ...el,
              position:
                el.accountPositionMappings.length &&
                el.accountPositionMappings[0].position.name,
              hospital: this.getDataHospital(
                el.accountRule?.accountRuleTagMappings || [],
              ),
            };
          }) || [];
        this.paging.total = data.total;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi',
          msg: error,
        });
      } finally {
        this.$store.commit('context/setLoading', false);
      }
    },
    getDataHospital(tags) {
      if (!tags || !tags.length) return null;
      const agencies = tags.filter((el) => el.tag.type === TAG_TYPE.AGENCY);
      return agencies.map((el) => el.tag.name).join(', ');
    },
    getStatus(status) {
      if (status) return 'active';
      return 'inactive';
    },
    searchDebounce(value) {
      this.filter.searchText = value;
      this.resetPaging();
    },
    resetPaging() {
      this.paging.page = 1;
      this.loadData();
    },
    getSearchDataPrepare() {
      this.$api
        .get('Admin/MessageTemplates/portal/PrepareSearchFormItem')
        .then(({ data }) => {
          const { agencyList, genderList, positionList, statusList } = data;
          this.agencies = agencyList;
          this.positions = positionList;
          this.statuses = statusList;
          this.genders = genderList;
        })
        .catch((error) => {
          this.$toastr.e({
            title: 'Lỗi',
            msg: error,
          });
        });
    },
  },
  async created() {
    await Promise.all([this.loadData(), this.getSearchDataPrepare()]);
  },
};
</script>
<style lang="scss" scoped>
.status {
  padding: 6px 24px;
  border-radius: 20px;
  color: #fff;
  text-transform: capitalize;
  width: 96px;
  text-align: center;

  &.active {
    background: #21a567;
  }

  &.inactive {
    background: #ff5756;
  }
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cell-overflow {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dropdown-form-filter {
  .dropdown-menu {
    .container {
      width: 850px;
    }
  }
}

.search-input {
  min-width: 220px;
}
</style>
